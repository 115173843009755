import { Link } from "gatsby"
import React, { useState } from "react"
import { Button } from "react-bootstrap"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const ProjectsPage = () => {
  const [hover, setHover] = useState({
    0: false,
    1: false,
    2: false,
  })

  return (
    <Layout>
      <SEO title="Current Projects - PCS" description="" lang="en" meta={[]} />

      {/* Hero */}
      <div className="banner-image pcs secondary">
        <div className="hero-text">
          <h1>Projects</h1>
        </div>
      </div>

      <section>
        <div className="container">
          <p className="text-center">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sequi
            saepe quod ipsum consequatur accusamus explicabo vel, officiis
            reprehenderit. Quo dolorum officiis harum tempora non porro eaque
            amet, eligendi veniam doloribus odit nihil asperiores ea, pariatur
            fugit vel debitis doloremque aspernatur, a quibusdam. Tempora ut,
            qui molestiae animi enim voluptates! Ea!
          </p>
        </div>
      </section>

      {/* Projects Section */}
      <section className="projects-page bg-darkened">
        <div className="container">
          <div className={`project-cards row ${hover[0] ? "hovered" : ""}`}>
            <Link
              className="col-md-6 primary-block"
              to="/pcs/projects/hardware-data-structures"
              onMouseEnter={e => setHover({ ...hover, 0: true })}
              onMouseLeave={e => setHover({ ...hover, 0: false })}
            >
              <div className="img-container">
                <div className="img-div one"></div>
              </div>
              <div>
                <h3>Hardware Data Structures</h3>

                <p>
                  Implementation and protocol for efficient
                  host/accelerator-to-accelerator intercommunication.
                </p>

                <h5>View Project</h5>
                <div className="category-label left">
                  <span className="hybrid">Hardware Stacks</span>
                </div>
              </div>
            </Link>

            <div className="col-md-4 offset-lg-2 sub-block">
              <h4 className="text-center">Sub-projects</h4>

              <div>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/hardware-data-structures/#fshmem-gasnet-fpga"
                >
                  FSHMEM: GasNet-FPGA
                </Button>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/hardware-data-structures/#apache-arrow"
                >
                  Apache Arrow*
                </Button>
              </div>
            </div>
          </div>

          <div className={`project-cards row ${hover[1] ? "hovered" : ""}`}>
            <Link
              className="col-md-6 primary-block"
              to="/pcs/projects/hybrid-programming-model"
              onMouseEnter={e => setHover({ ...hover, 1: true })}
              onMouseLeave={e => setHover({ ...hover, 1: false })}
            >
              <div className="img-container">
                <div className="img-div two"></div>
              </div>
              <div>
                <h3>Hybrid Programming Model</h3>

                <p>
                  Performance prediction model and software abstractions for
                  accelerator-HPC tools integration.
                </p>

                <h5>View Project</h5>
                <div className="category-label left">
                  <span className="hybrid">System Software</span>
                </div>
              </div>
            </Link>

            <div className="col-md-4 offset-lg-2 sub-block">
              <h4 className="text-center">Sub-projects</h4>

              <div>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/hybrid-programming-model/#pgas-open-shmem"
                >
                  PGAS/OpenSHMEM
                </Button>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/hybrid-programming-model/#hybrid-spmd-mpsd"
                >
                  Hybrid SPMD-MPSD
                </Button>
              </div>
            </div>
          </div>
          <div className={`project-cards row ${hover[2] ? "hovered" : ""}`}>
            <Link
              className="col-md-6 primary-block"
              to="/pcs/projects/edge-neural-network"
              onMouseEnter={e => setHover({ ...hover, 2: true })}
              onMouseLeave={e => setHover({ ...hover, 2: false })}
            >
              <div className="img-container">
                <div className="img-div three"></div>
              </div>
              <div>
                <h3>Edge Neural Network</h3>

                <p>
                  Graph partitioning, optimization, and dataflow schemes for
                  large DNNs.
                </p>

                <h5>View Project</h5>
                <div className="category-label left">
                  <span className="hybrid">Data Engineering</span>
                </div>
              </div>
            </Link>

            <div className="col-md-4 offset-lg-2 sub-block">
              <h4 className="text-center">Sub-projects</h4>

              <div>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/edge-neural-network/#cnn-rnn"
                >
                  CNN AND RNN
                </Button>
                <Button
                  className="mb-2 projects-btn"
                  as={Link}
                  to="/pcs/projects/edge-neural-network/#recommender"
                >
                  Recommender
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectsPage
